import { BrowserRouter, Route, Routes } from 'react-router-dom';
import QR from './pages/qr';
import Home from './pages/home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/qr"element={<Home />} >
          <Route path=":uuid" element={<QR />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
