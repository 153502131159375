import { useEffect } from "react";
import { useParams } from "react-router-dom"

const QR = () => {
    let uuid = useParams().uuid

    useEffect(() => {
        
        return () => {
            fetch('https://6mrtoav4a4.execute-api.us-east-1.amazonaws.com/latest/qr/redirect/'+uuid)
                .then(response => response.json())
                .then(res => {
                    console.log(res) /*setRedirectionURL(res.destination_url)*/
                    window.location.replace(res.destination_url)
                })
        };
    }, [uuid]);
    return (
        <>
            <h1>Redireccionando al comercio... </h1>
            {/* { redirectionURL ?? window.location.replace(redirectionURL)} */}
        </>

    )
}
export default QR