import { Outlet } from "react-router-dom"

const Home = () => {
    return (
        <>
            {/* <h1>Home {params.length} </h1> */}
            <Outlet />
        </>
    )
}
export default Home